import { useTranslation } from "react-i18next";
import { Alert } from "~/components/ui/alert";

export default function Route() {
	const { t } = useTranslation();

	return (
		<div className="p-16">
			<Alert>{t("ROOT_PAGE_MESSAGE")}</Alert>
		</div>
	);
}
